<template>
  <publicExport :exportTypeList="exportTypeList"></publicExport>
</template>
<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import publicExport from "@/components/common/publicExport";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "exportTask",
  data() {
    return {
      exportTypeList: [
        {value: 'productExport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1000725')},
        {value: 'inventoryExport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1000759')},
        {value: 'inventoryLogExport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1005851')},
        {value: 'packageExport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1002597')},
        {value: 'ymsInventoryExport', name: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1005850')}`},
        {value: 'supplierBillExport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1005852')},
        {value: 'wmsPickupOrderExport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1005853')},
        {value: 'packageInfoOrderExport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1001448')},
        {value: 'packageInfoStockExport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1001341')},
      ]
    };
  },
  components: {
    publicExport
  }
};
</script>

<style scoped>

</style>
